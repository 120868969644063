import { render, staticRenderFns } from "./exponews.vue?vue&type=template&id=6ad94728&scoped=true&"
import script from "./exponews.vue?vue&type=script&lang=ts&"
export * from "./exponews.vue?vue&type=script&lang=ts&"
import style0 from "./exponews.vue?vue&type=style&index=0&lang=less&"
import style1 from "./exponews.vue?vue&type=style&index=1&id=6ad94728&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ad94728",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionTitle: require('/root/workspace/com.jufair.vue_Z6yh/components/SectionTitle/index.vue').default})
